@import "./base.less";

@media screen and (min-width: 828px) and (max-width: 1000px) {
  .page-header {
    padding: @space 30px;

    .page-header-container {
      display: none;
    }

    .page-header-simple {
      display: block;
    }
  }

  .page-container {
    padding: 60px 20px;
  }
  .idxPage{
    margin-top: 40px !important;
  }
  .page-first {
    .first-slogan {
      width: 354px;
    }

    .page-first-slogan {
      width: 624px;
      font-size: 34px;
      ul, .first-play {
        margin-top: 50px;
        li {
          padding: 0 40px;
        }
      }
    }
  }

  .page-adv-block, .page-join-block {
    align-items: flex-start;

    .page-adv-item, .page-join-item {
      width: 49%;
    }

    .page-join-item {
      height: auto;
    }
  }
  .page-demo {
    height: 440px;
  }
}

@media screen and (min-width: 750px) and (max-width: 828px) {
  .page-first {
    background-size: cover;
    .first-slogan {
      width: 60%;
    }

    .page-first-slogan {
      width: 100%;
      height: 100px;
      font-size: 34px/2;

      ul:extend(.clean-padding) {
        display: flex;
        justify-content: space-around;
        padding: 0 @space;
      }

      ul, .first-play {
        margin-top: 50px/1.5;
        li {
          padding: 0 40px/2;
          flex: 1;
        }

        div.number {
          font-weight: 600;
          letter-spacing: 0;
        }

        p {
          margin-top: 10px;
        }
      }

      .first-play, video {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .page-header {
    padding: @space 30px;

    .page-header-container {
      display: none;
    }

    .page-header-simple {
      display: block;
    }
  }

  .page-container {
    padding: 60px 20px;
  }
  .idxPage{
    margin-top: 40px !important;
  }

  .page-first {
    background-size: cover;

    .first-slogan {
      width: 60%;
    }

    .page-first-slogan {
      height: 100px;
      width: 100%;
      font-size: 34px/2;
      ul, .first-play {
        margin-top: 50px/2;
        li {
          padding: 0 40px/3;
        }

        div.number {
          font-weight: 600;
          letter-spacing: 0;
        }

        p {
          margin-top: 10px;
        }
      }

      // .first-play, video {
      //   display: none;
      // }
    }
  }

  .page-grid li {
    max-width: 42%;

    // &:nth-child(n+3) {
    //   margin-top: 70px;
    // }
  }

  // 关于我们
  .page-about {
    // 第二层
    li > ul > li {
      margin-top: 15px;

      span {
        display: block;
        float: none;
      }
    }
  }

  // 英雄留步
  .page-adv-block, .page-join-block {
    .page-adv-item, .page-join-item {
      width: 100%;

      &:nth-child(2) {
        margin-top: 20px;
      }
    }

    .page-join-item {
      height: auto;
    }
  }
  .page-cooperate {
    height: 720px;
  }
  .page-demo {
    height: 880px;
  }

  // 联系我们
  .page-footer .footer-wrapper {
    .footer-qrcode {
      margin-bottom: 60px;
      float: none;
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-flow: row wrap;
    }
  }
  // 招聘详情
  .recruitment-top {
    padding-top: 0;
  }
  .recruitment-title {
    flex-flow: row wrap;
    padding: 30px 15px 56px;
    .recruitment-phone {
      margin-top: 10px;
    }
    .jobWelfare{
      width: auto;
      justify-content: start;
      div{
        margin: 0 10px 10px 0;
      }
    }
  }
  .recruitment-detial {
    flex-flow: row wrap;
    .recruitment-detials-other{
      width: 100%;
      margin-left: 0;
      margin-top: 20px;
      padding-bottom: 20px;
    }
  }
  .preceptH2{
    padding: 0 22px 0 20px;
    li{
      width: 38%;
    }
  }
}

@media screen and (max-width: 640px) {
  .page-first {
    background-size: cover;

    .page-first-slogan {
      font-size: 34px/2.2;
    }
  }
}

@media screen and (max-width: 1000px) {
  .page-first{
    display: none;
  }
  .first-play{
    width: 50px !important;
    height: 50px !important;
  }
  .idxPage{
    padding-top: 120px;
    .gridLine{
      display: none;
    }
    li{
      margin-bottom: 30px;
    }
  }
  .server-grid{
    justify-content: space-around;
  }
}